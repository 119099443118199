import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ShareLinks from "../component/Blog/ShareLinks";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/10-tips-to-sell-your-home";
  return (
    <LayoutWrapper
      title="Top 10 Tips to Sell Your Home for Top Dollar"
      desc="Uncover 10 expert tips for selling your home at top dollar in today’s market, from finding a realtor to strategic upgrades and pricing. Learn more!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Top 10 Tips to Sell Your Home for Top Dollar
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  April 15, 2023
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Top 10 Tips to Sell Your Home for Top Dollar.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                In the current housing market, we are seeing a shift from the days of multiple
                offers, bidding wars, overpriced listings, and rock-bottom interest rates. This
                correction means sellers must find innovative ways to maximize their sale price. To
                help sellers, we created a list of ten tips to sell your home for top dollar.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Shop Around for the Right Realtor
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                When it comes to home improvement projects, it's important to invest in those that
                offer the best return on investment. For example, while a kitchen remodel can cost
                tens of thousands of dollars, the likelihood of seeing a return on that investment
                is low.{" "}
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Instead, consider making minor upgrades like painting cabinets and replacing
                outdated hardware and countertops, which cost less but can still increase the value
                of your home and offer a good return on investment.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Talk About the Extras
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You may consider motion-sensitive outdoor lighting, automatic garage door timers,
                and phone-activated security systems to be unremarkable, but they can actually make
                a significant difference in how prospective buyers view your home. Be sure to talk
                about these extras in the listing description.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Increase Curb Appeal
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Giving your home’s curb appeal a boost doesn’t have to come with a hefty price tag.
                Subtle upgrades like replacing your mailbox, installing outdoor lighting, trimming
                your hedges, edging your lawn, and adding flower boxes to your windows can make a
                big impact without breaking the bank.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Invest in Better Photos
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It may be tempting to pay your realtor to take photos of your home to save some
                cash. But the upfront cost of paying a professional photographer is almost always a
                solid investment. Professional photographers have an eye for detail, know lighting,
                have the right equipment, and will use angles that showcase the best side of your
                home.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Set the Stage
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Staging your home is another way to sell your home for top dollar. Start by
                decluttering and de-personalizing your space. Remove family photos, religious items,
                and excess furniture and belongings—especially in the house's main living areas.
                Make your home look spacious, well-lit, and inviting.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Create a Video Tour
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Unless you're selling a high market-value home, most realtor listings begin and end
                with photographs and a listing description. But giving prospective buyers a video
                tour of the property is an inexpensive, novel way to bring your property to life and
                is well worth the effort.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Get Your Own Inspection
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Getting your own home inspection will ensure you identify any issues or repairs that
                will be flagged on the buyer's inspection report. By obtaining your own inspection,
                you can prevent surprises and delays and potentially increase the value of your
                home.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Offer Insider Information in Listing Description
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As a homeowner, you have insider knowledge of the local neighborhood that can help
                make your listing stand out from the competition. Consider including information on
                nearby amenities, such as popular coffee shops, restaurants, or other attractions.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Set Realistic Listing Prices
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Since the days of bidding wars are coming to a close, it’s time to set realistic
                listing prices. Overpriced homes are likely to sit on the market, which can
                negatively impact buyer interest. If a home sits on the market for a long time,
                buyers may assume there is something wrong with the property. Set realistic prices,
                and you will attract qualified buyers.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Find the Home of Your Dreams
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                These ten tips to sell your home for top dollar can help you make your dream home a
                reality, regardless of market conditions. Contact The Selby Team to get started on
                selling your home and finding a new one.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
